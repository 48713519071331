import { faEye, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { ModalProps } from './Modal.types';
import {
  StyledClose,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledModal,
  StyledPrivacy
} from './ModalFullscreen.styled';
import { Heading1 } from './Typography';

const ModalFullscreen: React.FC<ModalProps> = ({
  title,
  children,
  onClose,
  variant,
  isPrivate,
  isPublic,
  hasFooter
}) => {
  const { t } = useI18n('common');

  return (
    <StyledModal variant={variant}>
      {onClose && (
        <StyledClose
          type="button"
          onClick={onClose}
          aria-label={t('close') || ''}
        >
          <FontAwesomeIcon icon={faTimes} />
        </StyledClose>
      )}
      {(isPrivate || isPublic) && (
        <StyledPrivacy>
          <FontAwesomeIcon icon={faEye} />
          {isPrivate && <span>Dato interno siempre privado</span>}
          {isPublic && <span>Solo se verá si publicas tu perfil</span>}
        </StyledPrivacy>
      )}
      <StyledContainer className={hasFooter ? 'has-footer' : ''}>
        {title && (
          <Heading1 editorial alignLeft>
            {title}
          </Heading1>
        )}
        <StyledContent>
          {children}
          {hasFooter && <StyledFooter>{hasFooter}</StyledFooter>}
        </StyledContent>
      </StyledContainer>
    </StyledModal>
  );
};

export default ModalFullscreen;
