import styled, { css } from 'styled-components';
import { color, fontSize, fontWeight, radius, screen, spacing } from 'theme';
import { ModalColor } from './Modal.types';
import { Heading1 } from './Typography.styled';

const colorVariants: Record<
  ModalColor,
  {
    backgroundColor?: string | ((key: any) => string);
    color?: string | ((key: any) => string);
    closeBg?: string | ((key: any) => string);
    closeIcon?: string | ((key: any) => string);
  }
> = {
  primary: {
    backgroundColor: color('sand_100'),
    color: color('text_default'),
    closeBg: color('midnight_800'),
    closeIcon: color('midnight_300')
  },
  secondary: {
    backgroundColor: color('emerald_900'),
    color: color('white'),
    closeBg: color('emerald_600'),
    closeIcon: color('midnight_300')
  },
  dark: {
    backgroundColor: color('midnight_900'),
    color: color('text_default_inverse'),
    closeBg: color('midnight_200'),
    closeIcon: color('midnight_800')
  }
};

export const StyledClose = styled.button`
  position: fixed;
  top: 0;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  border: 0;
  border-bottom-left-radius: ${radius('md')};
  background-color: ${color('midnight_800')};
  color: ${color('midnight_300')};
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  --webkit-appearance: none;

  svg {
    width: ${spacing('xl')} !important;
    height: ${spacing('xl')} !important;
  }
`;

export const StyledModal = styled.div<{
  variant?: ModalColor;
}>`
  overflow: auto;
  background-color: ${colorVariants.primary.backgroundColor};
  color: ${colorVariants.primary.color};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  ${({ variant }) =>
    variant &&
    colorVariants[variant] &&
    css`
      background-color: ${colorVariants[variant].backgroundColor};
      color: ${colorVariants[variant].color};

      ${StyledClose} {
        background-color: ${colorVariants[variant].closeBg};
        color: ${colorVariants[variant].closeIcon};
      }
    `}
`;

export const StyledContainer = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;

  @media (min-width: ${screen('md')}) {
    padding: ${spacing('xl')};
  }

  ${Heading1} {
    margin-bottom: ${spacing('lg')};

    @media (min-width: ${screen('md')}) {
      margin-bottom: ${spacing('xl')};
    }
  }

  &.has-footer {
    padding-bottom: 5rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledPrivacy = styled.div`
  position: absolute;
  top: 0;
  left: ${spacing('xl2')};
  background-color: ${color('emerald_850')};
  color: ${color('white')};
  padding: ${spacing('md')} 1.25rem;
  font-size: ${fontSize('xs')};
  line-height: 1;
  font-weight: ${fontWeight('extraBold')};
  border-bottom-left-radius: ${radius('md')};
  border-bottom-right-radius: ${radius('md')};
  display: flex;
  align-items: center;
  gap: ${spacing('xs2')};

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
  }

  span {
    @media (max-width: ${screen('mdMax')}) {
      display: none;
    }
  }
`;

export const StyledFooter = styled.div`
  margin-top: auto;
`;
