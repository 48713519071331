import {
  StyledClose,
  StyledModal,
  StyledContainer
} from 'components/shared/ModalFullscreen.styled';
import styled, { createGlobalStyle } from 'styled-components';
import { color, fontFamily, fontSize, spacing, screen } from 'theme';

export const GlobalStyles = createGlobalStyle`
  :root {
    --hacker-green: #47DF84;
  }

  ${StyledModal} {
    background-color: ${color('black')};
    padding: 0;
  }

  ${StyledClose} {
    z-index: 1;
    width: ${spacing('xl2')};
    height: ${spacing('xl2')};
    top: ${spacing('sm')};
    right: ${spacing('sm')};
    border-radius: 0;
    background-color: ${color('black')};
    color: var(--hacker-green);

  }

  ${StyledContainer} {
    padding: 0;
    flex-grow: 1;
  }
`;

export const StyledHeader = styled.div`
  background-color: var(--hacker-green);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${spacing('xl4')};
`;

export const StyledAnonymous = styled.div`
  flex-grow: 1;
  margin-top: ${spacing('xl4')};
  padding: ${spacing('md')};
  border: ${spacing('xs2')} solid var(--hacker-green);
  border-top: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: ShareTechMono, ${fontFamily('display')}, sans-serif;
`;

export const StyledSubtitle = styled.p`
  font-size: ${fontSize('xl')};
  line-height: 1.125;
  text-transform: uppercase;
  color: var(--hacker-green);
  margin-top: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    font-size: ${fontSize('xl3')};
    line-height: 1.6;
  }
`;

export const StyledBox = styled.div`
  margin-top: ${spacing('xl4')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2347DF84FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  max-width: 40rem;
  padding: ${spacing('mdlg')};
  font-size: ${fontSize('md')};

  @media (min-width: ${screen('md')}) {
    padding: ${spacing('xl2')};
    font-size: ${fontSize('xl')};
  }

  a {
    display: inline-block;
    padding: ${spacing('sm')};
    background-color: var(--hacker-green);
    color: ${color('text_black')};
    text-transform: uppercase;
    margin-top: ${spacing('xs')};

    @media (min-width: ${screen('md')}) {
      font-size: ${fontSize('xl3')};
    }

    &:hover {
      opacity: 0.75;
    }
  }
`;

export const StyledBoxTitle = styled.p`
  padding-top: ${spacing('md')};
  padding-bottom: ${spacing('md')};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: ${color('black')};
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: ${color('black')};
  }
`;
