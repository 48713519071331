import { useI18n } from 'hooks/useI18n';
import ReactModal from 'react-modal';
import {
  GlobalStyles,
  StyledAnonymous,
  StyledBox,
  StyledBoxTitle,
  StyledHeader,
  StyledSubtitle
} from './AnonymousModal.styled';
import ModalFullscreen from 'components/shared/ModalFullscreen';
import { getPublicPath } from 'utils/paths';
import HtmlTrans from 'components/shared/HtmlTrans';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const AnonymousModal: React.FC<Props> = ({ isOpen, close }) => {
  const { t } = useI18n('home');

  return (
    <ReactModal isOpen={isOpen} onRequestClose={close}>
      <ModalFullscreen onClose={close}>
        <StyledHeader></StyledHeader>
        <StyledAnonymous>
          <img
            src={getPublicPath('/images/landing/anonymousBanner/header.png')}
            alt={t('anonymous.title')}
            width="1200"
          />
          <StyledSubtitle>
            <HtmlTrans ns="home" i18nKey="anonymous.subtitle" />
          </StyledSubtitle>
          <StyledBox>
            <StyledBoxTitle>{t('anonymous.box-title')}</StyledBoxTitle>
            <p>{t('anonymous.join')}</p>
            <a
              href="https://getmanfred.typeform.com/to/VgdVCVAJ"
              target="_blank"
            >
              {t('anonymous.cta')}
            </a>
          </StyledBox>
        </StyledAnonymous>
        <GlobalStyles />
      </ModalFullscreen>
    </ReactModal>
  );
};

export default AnonymousModal;
