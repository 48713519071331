import Page from 'components/layout/Page';
import LandingCV from 'components/marketingSite/landing/LandingCV';
import LandingFeatures from 'components/marketingSite/landing/LandingFeatures';
import LandingHero from 'components/marketingSite/landing/LandingHero';
import LandingManifest from 'components/marketingSite/landing/LandingManifest';
import LandingRoadmap from 'components/marketingSite/landing/LandingRoadmap';
import Faqs from 'components/marketingSite/shared/Faqs';
import { LinkText } from 'components/shared/LinkText';
import { useI18n } from 'hooks/useI18n';
import { Trans } from 'next-i18next';
import { useRouter } from 'next/router';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { StyledFaqs, StyledLanding } from './LandingComponent.styled';
import AnonymousModal from './anonymousModal/AnonymousModal';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useState } from 'react';

const LandingComponent: React.FC = () => {
  const { t } = useI18n('home');
  const { locale } = useRouter();

  const [isAnonymousModalOpen, setAnonymousModalOpen] = useState(true);

  function closeAnonymousModal(): void {
    setAnonymousModalOpen(false);
  }

  const { isEnabled: isAnonymousBannerEnabled } = useFeatureFlag(
    'talent_banner_anonymous'
  );

  const desc = (
    <Trans
      ns={'home'}
      i18nKey={'faqs.desc'}
      components={{
        mail: <a href="mailto:hello@getmanfred.com">drop us a line</a>
      }}
    ></Trans>
  );

  const faqs = [
    {
      question: t('faqs.faqsList.faq1.question'),
      answer: (
        <Trans
          ns={'home'}
          i18nKey={'faqs.faqsList.faq1.answer'}
          components={{
            team: <LinkText href={getAppUrl(APP_URLS.team, locale)} />
          }}
        ></Trans>
      ),
      renderComponent: true
    },
    {
      question: t('faqs.faqsList.faq2.question'),
      answer: t('faqs.faqsList.faq2.answer')
    },
    {
      question: t('faqs.faqsList.faq3.question'),
      answer: (
        <Trans
          ns={'home'}
          i18nKey={'faqs.faqsList.faq3.answer'}
          components={{
            li: <li />,
            ol: <ol />,
            privatePolicy: (
              <LinkText href={getAppUrl(APP_URLS.privacyPolicy, locale)} />
            ),
            mac: <a href="https://github.com/getmanfred/mac"></a>
          }}
        ></Trans>
      ),
      renderComponent: true
    },
    {
      question: t('faqs.faqsList.faq4.question'),
      answer: (
        <Trans
          ns={'home'}
          i18nKey={'faqs.faqsList.faq4.answer'}
          components={{
            mail: <a href="mailto:team@getmanfred.com"></a>
          }}
        ></Trans>
      ),
      renderComponent: true
    },
    {
      question: t('faqs.faqsList.faq5.question'),
      answer: t('faqs.faqsList.faq5.answer')
    },
    {
      question: t('faqs.faqsList.faq6.question'),
      answer: (
        <Trans
          ns={'home'}
          i18nKey={'faqs.faqsList.faq6.answer'}
          components={{
            li: <li />,
            ul: <ul />,
            strong: <strong />,
            contracts: (
              <a
                href="https://github.com/getmanfred/contracts"
                target="_blank"
                rel="noreferrer"
              ></a>
            )
          }}
        ></Trans>
      ),
      renderComponent: true
    },
    {
      question: t('faqs.faqsList.faq7.question'),
      answer: t('faqs.faqsList.faq7.answer')
    },
    {
      question: t('faqs.faqsList.faq8.question'),
      answer: (
        <Trans
          ns={'home'}
          i18nKey={'faqs.faqsList.faq8.answer'}
          components={{
            manfredite: (
              <LinkText href={getAppUrl(APP_URLS.beManfredite, locale)} />
            )
          }}
        ></Trans>
      ),
      renderComponent: true
    },
    {
      question: t('faqs.faqsList.faq9.question'),
      answer: (
        <Trans
          ns={'home'}
          i18nKey={'faqs.faqsList.faq9.answer'}
          components={{
            mail: <a href="mailto:team@getmanfred.com"></a>,
            team: <LinkText href={getAppUrl(APP_URLS.team, locale)} />
          }}
        ></Trans>
      ),
      renderComponent: true
    }
  ];

  return (
    <Page hasScrollTop pageVariant="landing">
      <StyledLanding withNotification={false}>
        <LandingHero />
        <LandingFeatures />
        <LandingRoadmap />
        <LandingCV />
        <LandingManifest />
        <StyledFaqs>
          <Faqs faqs={faqs} title={t('faqs.title')} desc={desc} />
        </StyledFaqs>
      </StyledLanding>
      {isAnonymousBannerEnabled && (
        <AnonymousModal
          isOpen={isAnonymousModalOpen}
          close={closeAnonymousModal}
        />
      )}
    </Page>
  );
};

export default LandingComponent;
